
import { defineComponent } from "vue";
import randomString from "random-string";
import { mapGetters } from "vuex";
import * as requestActions from "../store/requestActions";
import i18n from "../lang";
import "v-calendar/dist/style.css";
import { Calendar, DatePicker } from "v-calendar";
import axios from "axios";

export default defineComponent({
  name: "welcome",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Calendar,
    // eslint-disable-next-line vue/no-unused-components
    DatePicker,
  },
  data() {
    return {
      later: false,
      bookingDate: null,
      inputValue: null,
      freeBookingTimes: [],
      notAvaiableDate: false,
      setBookingDate: null,
      user: {
        name: "",
        phone: "",
      },
      locales: ["ru", "ua", "en", "de"],
      loading: false,
    };
  },

  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem("locale", locale);
        //this.$router.go(0);
        this.$i18n.locale = locale;
      }
    },

    getRoomId() {
      return (
        this.$route?.query?.roomId || randomString({ length: 8 }).toLowerCase()
      );
    },

    formVerified() {
      const errors = [];

      if (this.user.name.length < 1) {
        errors.push("notifications.error.name");
      }

      if (this.user.name.length > 20) {
        errors.push("notifications.error.nameBig");
      }

      if (!~this.user.phone.search(/^\+380(\d){9}$/g)) {
        errors.push("notifications.error.phone");
      }

      if (errors.length) {
        errors.forEach((e, i) => {
          setTimeout(() => {
            requestActions.notify({
              type: "danger",
              text: i18n.global.t(e),
            });
          }, 100 * i);
        });
      }

      return !errors.length;
    },

    verifyBookingData() {
      if(!this.bookingDate || this.bookingDate === 0) {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("welcome.setBookingDate"),
        });
        return false;
      }

      if(!this.setBookingDate || this.setBookingDate === 0) {
        requestActions.notify({
          type: "danger",
          text: i18n.global.t("welcome.setBookingTime"),
        });
        return false;
      }

      return true;
    },

    joinRoom() {
      if (!this.formVerified()) {
        return;
      }

      this.loading = true;

      localStorage.setItem("user", JSON.stringify(this.user));

      const route = {
        name: "room",
        params: { roomId: this.getRoomId() },
      };

      this.$router.push(route);
    },

    bookingFreeDates() {
      return Object.values(JSON.parse(JSON.stringify(this.freeBookingTimes)));
    },

    async bookingRoom() {
      if (!this.formVerified()) {
        return;
      }
      if (!this.verifyBookingData()) {
        return;
      }
      let result;

      await axios.post(
          process.env.VUE_APP_SERVER_LINK +
          `/room/reserve`,
          {
            phone: this.user.phone,
            displayName: this.user.name,
            email: null,
            date: `${new Date(this.setBookingDate).getTime()}`
          }
      ).then((response => {
        result = response.data
      }));

      localStorage.setItem("booking", JSON.stringify(result));

      const route = {
        name: "reserved",
      };

      this.$router.push(route);
    },
  },

  mounted() {
    if (this.$route.query.locale) {
      this.switchLocale(this.$route.query.locale);

      const query = Object.assign({}, this.$route.query);
      delete query.locale;

      this.$router.replace({ query });
    }
  },

  watch: {
    async bookingDate() {
      let needBookingDate = new Date(this.bookingDate).getTime();
      let freeTime = {};
      await axios.get(
          process.env.VUE_APP_SERVER_LINK +
          `/rooms/reserve/${needBookingDate}`
      ).then((response => {
          freeTime = response.data
      })).catch((() => {
          freeTime = false;
      }));

      if (freeTime
          && Object.keys(freeTime).length > 0
          && Object.getPrototypeOf(freeTime) === Object.prototype) {
        this.notAvaiableDate = false;
        this.freeBookingTimes = freeTime;
      } else {
        this.notAvaiableDate = true;
        this.freeBookingTimes = [];
      }
    },
  },

  computed: {
    ...mapGetters(["roomClient"]),
  },
});
